<template>
  <div class="container-sm container-md" id="worksheet">
    <div class="card">
      <a-tabs defaultActiveKey="1">
        <a-tab-pane key="1">
          <span slot="tab">
            <a-icon type="bars" />List
          </span>
          <worksheet-list :schoolList="schoolList"></worksheet-list>
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab">
            <a-icon type="plus" />Create
          </span>
          <create-worksheet :schoolList="schoolList"></create-worksheet>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import WorksheetList from '@/components/Worksheet/List.vue';
import CreateWorksheet from '@/components/Worksheet/Create.vue';
import schoolService from '@/services/school';

export default {
  components: {
    WorksheetList,
    CreateWorksheet
  },
  data(){
    return {
      schoolList: [],
    }
  },
  created(){
    this.setSchoolList();
    this.$store.commit('setActiveNavText', '6');
  },
  methods: {
    async setSchoolList() {
      const result = await schoolService.getSchoolList();
      this.schoolList = result;
    },
  }
}
</script>
<style scoped>
.card {
  box-shadow: 0 0px 3px rgba(0,0,0,0.12), 0 0px 2px rgba(0,0,0,0.24);
  margin-top: 20px;
  padding: 30px;
}
</style>