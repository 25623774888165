import api from './base';

export default{
  async getWorksheets(payload) {
    const result = await api().get('/api/BranchWorksheet/' + payload);
    return result.data;
  },
  async deleteWorksheet(payload) {
    const result = await api().delete('/api/BranchWorksheet/' + payload);
    return result.data;
  },
  async createWorksheet(data, params, headers) {
    const result = await api().post(`/api/BranchWorksheet?BranchID=${params.BranchID}&UnlockWeekID=${params.UnlockWeekID}&desc=${params.description}`, data, headers);
    return result.data;
  },
  async getWeeks() {
    const result = await api().get('/api/UnlockWeek');
    return result.data;
  },
}