<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-sm-12 col-md-6">
        <a-form-item label="Code" :label-col="{ sm: 4 }" :wrapper-col="{ xs: 24, sm: 20, md: 20 }">
          <a-input-search placeholder="Fill with activation code" @search="checkCode" enterButton allowClear />
        </a-form-item>
      </div>
    </div>
    <a-form :form="form" @submit.prevent="validateWorksheet">
      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-6">
          <a-form-item label="School" :label-col="{ sm: 4, md: 6}" :wrapper-col="{ xs: 24, sm: 20, md: 18 }">
            <a-select
              showSearch
              placeholder="Select a School"
              optionFilterProp="children"
              @change="handleChangeSchool"
              v-decorator="[
                'school',
                { rules: [{ required: true, message: 'Please select a school!' }] },
              ]"
              :filterOption="filterOption">
                <a-select-option 
                  v-for="school in schoolList"
                  :key="school.id" 
                  :value="school.id">
                    {{school.Name}}
                </a-select-option>
            </a-select>
          </a-form-item>
        </div>
        <div class="col-sm-12 col-md-6">
          <a-form-item label="Campus" :label-col="{ sm: 4, md: 6}" :wrapper-col="{ xs: 24, sm: 20, md: 18}">
            <a-select
              showSearch
              placeholder="Select a Campus"
              optionFilterProp="children"
              :disabled="campusList.length === 0"
              @change="handleChangeCampus"
              v-decorator="[
                  'Campus',
                  { rules: [{ required: true, message: 'Please select a campus!' }] },
                ]"
              :filterOption="filterOption">
              <a-select-option 
                v-for="campus in campusList"
                :key="campus.id"
                :value="campus.id">
                  {{campus.Name}}
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>
        <div class="col-sm-12 col-md-6">
          <a-form-item label="Class" :label-col="{ sm: 4, md:6 }" :wrapper-col="{ xs: 24, sm: 20, md: 18}">
            <a-select
            showSearch
            placeholder="Select a Class"
            optionFilterProp="children"
            :disabled="classList.length === 0"
            @change="handleChangeClass"
            v-decorator="[
                'Class',
                { rules: [{ required: true, message: 'Please select a class!' }] },
              ]"
            :filterOption="filterOption">
              <a-select-option 
                v-for="item in classList"
                :key="item.id"
                :value="item.id">
                  {{item.Name}}
              </a-select-option>
          </a-select>
          </a-form-item>
        </div>
        <div class="col-sm-12 col-md-6">
          <a-form-item label="Branch" :label-col="{ sm: 4, md: 6 }" :wrapper-col="{ xs: 24, sm: 20, md: 18 }">
            <a-select
              showSearch
              placeholder="Select a Branch"
              optionFilterProp="children"
              :disabled="branchList.length === 0"
              v-decorator="[
                  'BranchID',
                  { rules: [{ required: true, message: 'Please select a branch!' }] },
                ]"
              :filterOption="filterOption">
              <a-select-option 
                v-for="brach in branchList"
                :key="brach.id"
                :value="brach.id">
                  {{brach.Name}}
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>
        <div class="col-sm-12 col-md-6">
          <a-form-item label="Description" :label-col="{ sm: 5, md: 8, lg: 6 }" :wrapper-col="{ xs: 24, sm: 19, md: 16 , lg: 18}">
            <a-input placeholder="Description" v-decorator="['Description', { rules: [{ required: true, message: 'Please input a description!'}] }]"></a-input>
          </a-form-item>
        </div>
        <div class="col-sm-12 col-md-6">
          <a-form-item label="Status" :label-col="{ sm: 4, md: 6 }" :wrapper-col="{ xs: 24, sm: 20, md: 18 }">
            <a-select
              placeholder="Select a Status"
              v-decorator="[
                  'Status',
                  { initialValue: 1 },
                  { rules: [{ required: true, message: 'Please select a status!' }] },
                ]">
              <a-select-option :value="1">
                  Active
              </a-select-option>
              <a-select-option :value="0">
                  Deleted
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>
      </div>
      <div class="row" v-for="(k,index) in form.getFieldValue('keys')" :key="k">
        <div class="col-sm-12 col-md-6" >
          <div id="weeks">
            <a-form-item
              :label-col="{ sm: 4, md: 6 }" 
              :wrapper-col="{ xs: 24, sm: 20, md: 18 }"
              :label="index === 0 ? 'Week' : 'Week ' + (index + 1)"
              :required="true">
                <a-select
                  showSearch
                  placeholder="Select a unlock week"
                  optionFilterProp="children"
                  v-decorator="[
                        `weeks[${k}]`,
                        {
                          validateTrigger: ['change'],
                          rules: [
                            {
                              required: true,
                              message: 'Please select a week\'s or delete this field.',
                            },
                          ],
                        },
                      ]"
                  :filterOption="filterOption">
                    <a-select-option 
                      v-for="week in weekList"
                      :key="week.id" 
                      :value="week.id">
                        {{week.Week}}
                    </a-select-option>
                </a-select>
            </a-form-item>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div id="document">
            <a-form-item
              :label-col="{ sm: 6, md: 8, lg: 6 }" 
              :wrapper-col="{ xs: 24, sm: 18, md: 16, lg: 18 }"
              :label="index === 0 ? 'Document' : 'Document ' + (index + 1)"
              :required="true">
                <a-upload
                  accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  :beforeUpload="beforeUpload"
                  v-decorator="[
                      `document[${k}]`,
                      {
                        getValueFromEvent: normFile,
                        validateTrigger: ['change'],
                        rules: [
                          {
                            required: true,
                            message: 'Please select a document'
                          },
                        ],
                      },
                    ]"
                    style="width: 60%; margin-right: 8px"
                  >
                  <a-button> <a-icon type="upload" /> Select File </a-button>
                </a-upload>
                <a-icon
                  v-if="form.getFieldValue('keys').length > 1"
                  class="dynamic-delete-button"
                  type="minus-circle-o"
                  :disabled="form.getFieldValue('keys').length === 1"
                  @click="() => remove(k)"
                />
            </a-form-item>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-6">
          <a-form-item :label-col="{ sm: 5 }" :wrapper-col="{ xs: 24, sm: {span: 10, offset: 7} }">
            <a-button type="dashed" :block="true" @click="add">
              <a-icon type="plus" /> Add More Document
            </a-button>
          </a-form-item>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-2 text-center">
          <a-button htmlType="submit" type="primary" shape="round" :block="true" :loading="loading">Add</a-button>
        </div>
      </div>
    </a-form>
  </div>
</template>
<script>
import campusService from '@/services/campus';
import branchService from '@/services/branch';
import classService from '@/services/class';
import worksheetService from '@/services/worksheet';
import studentService from '@/services/student';

export default {
  props: ['schoolList'],
  data(){
    return {
      campusList: [],
      classList: [],
      branchList: [],
      weekList: [],
      fileList: [],
      uploading: false,
      id: 1,
      loading: false,
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'worksheet' });
    this.form.getFieldDecorator('keys', { initialValue: [], preserve: true });
  },
  created() {
    this.setWeeks();
    this.form.setFieldsValue({
      keys: [0],
    });
  },
  methods: {
    async handleChangeSchool(schoolId) {
      this.campusList = [];
      this.classList = [];
      this.branchList = [];
      const result = await campusService.getCampusList(schoolId);
      this.campusList = result;
    },
    async handleChangeCampus(campusId) {
      this.classList = [];
      this.branchList = [];
      const result = await classService.getClassList(campusId);
      this.classList = result;
    },
    async handleChangeClass(classId) {
      this.branchList = [];
      const result = await branchService.getBranchList(classId);
      this.branchList = result;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    validateWorksheet() {
      this.form.validateFields( (err, values) => {
        if (!err) {
          this.createWorksheet(values);
        }
      });
    },
    async createWorksheet(values) {
      this.loading = true;
      const frm = new FormData();
      const fileList = values.document.filter(Boolean);
      fileList.forEach(element => {
        frm.append('upload',element[0].originFileObj);
      });
      const description = values.Description.split(" ").join("_").split("&").join("%");
      const weeks = values.weeks.filter(Boolean);
      const result = await worksheetService.createWorksheet(frm,
      {
          BranchID: values.BranchID,
          UnlockWeekID: weeks,
          description: description
      },
      { headers: {'Content-Type': 'multipart/form-data' } });
      if(result) { 
        this.$message.success('Worksheet\'s Created!');
        this.form.resetFields();
      }
      this.loading = false;
    },
    async checkCode(value) {
      if(value) {
        const result = await studentService.getInfoByCode({
        LoginCode: value 
        });
        if(result === 'Code is invalid') {
          this.$message.error('Code is invalid');
        } else {
          const campusList = await campusService.getCampusList(result[0].SchoolID);
          this.campusList = campusList;
          const classList = await classService.getClassList(result[0].CampusID);
          this.classList = classList;
          const branchList = await branchService.getBranchList(result[0].ClassID);
          this.branchList = branchList;

          this.form.setFieldsValue({
            school: result[0].SchoolID,
            Campus: result[0].CampusID,
            Class: result[0].ClassID,
            BranchID: result[0].BranchID,
          });
        }
      }
    },
    async setWeeks() {
      const result = await worksheetService.getWeeks();
      this.weekList = result;
    },
    remove(k) {
      const keys = this.form.getFieldValue('keys');
      if (keys.length === 1) {
        return;
      }
      this.form.setFieldsValue({
        keys: keys.filter(key => key !== k),
      });
    },
    add() {
      const keys = this.form.getFieldValue('keys');
      const nextKeys = keys.concat(this.id++);
      this.form.setFieldsValue({
        keys: nextKeys,
      });
    },
    normFile(e) {
      if (Array.isArray(e)) {
        return e;
      }
      if(e.fileList.length > 1){
        e.fileList.shift();
      }
      return e && e.fileList;
    },
    beforeUpload() {
      return false;
    },
  }
}
</script>
<style scoped>
  .dynamic-delete-button {
    cursor: pointer;
    font-size: 24px;
    color: #999;
    transition: all 0.3s;
    position: absolute;
    left: 140px;
    top: -4px;
  }
  .dynamic-delete-button:hover {
    color: red;
  }
  .dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
</style>