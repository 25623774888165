<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-sm-12 col-md-6">
        <a-select
          showSearch
          placeholder="Select a School"
          optionFilterProp="children"
          class="select-box"
          @change="handleChangeSchool"
          :filterOption="filterOption">
            <a-select-option 
              v-for="school in schoolList"
              :key="school.id" 
              :value="school.id">
                {{school.Name}}
            </a-select-option>
        </a-select>
      </div>
      <div class="col-sm-12 col-md-6">
        <a-select
          showSearch
          placeholder="Select a Campus"
          optionFilterProp="children"
          class="select-box"
          :disabled="campusList.length === 0"
          @change="handleChangeCampus"
          :filterOption="filterOption">
          <a-select-option 
            v-for="campus in campusList"
            :key="campus.id"
            :value="campus.id">
              {{campus.Name}}
          </a-select-option>
        </a-select>
      </div>
      <div class="col-sm-12 col-md-6">
        <a-select
          showSearch
          placeholder="Select a Class"
          optionFilterProp="children"
          class="select-box"
          :disabled="classList.length === 0"
          @change="handleChangeClass"
          :filterOption="filterOption">
            <a-select-option 
              v-for="item in classList"
              :key="item.id"
              :value="item.id">
                {{item.Name}}
            </a-select-option>
        </a-select>
      </div>
      <div class="col-sm-12 col-md-6">
        <a-select
          showSearch
          placeholder="Select a Branch"
          optionFilterProp="children"
          class="select-box"
          :disabled="branchList.length === 0"
          @select="handleChangeBrach"
          :filterOption="filterOption">
          <a-select-option 
            v-for="brach in branchList"
            :key="brach.id"
            :value="brach.id">
              {{brach.Name}}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="mt-4">
      <a-table
        :columns="columns" 
        :dataSource="worksheetList" 
        :pagination="false"
        :loading="tableLoading"
        rowKey="id">
          <span slot="worksheet-path" slot-scope="text">
            <a :href="text" target="_blank" style="color: red;">
              <a-icon type="file-pdf"></a-icon>
            </a>
          </span>
          <span slot="status" slot-scope="tag">
            <a-tag :color="(tag === 1 ? 'green' : 'volcano')">
              {{tag === 1 ? 'Active' : 'Deleted'}}
            </a-tag>
          </span>
          <span slot="action" slot-scope="record">
            <a-popconfirm
              title="Are you sure delete this worksheet?"
              @confirm="deleteWorksheet(record.id)"
              okText="Yes"
              cancelText="No">
                <a href="#">Delete</a>
            </a-popconfirm>
          </span>
        </a-table>
    </div>  
  </div>  
</template>
<script>
import campusService from '@/services/campus';
import branchService from '@/services/branch';
import classService from '@/services/class';
import worksheetService from '@/services/worksheet';

const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Description',
      dataIndex: 'Description',
    },
    {
      title: 'Worksheet Path',
      dataIndex: 'WorksheetPath',
      align: 'center',
      scopedSlots: { customRender: 'worksheet-path' },
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      scopedSlots: { customRender: 'status' }
    },
    {
      title: 'Action',
      scopedSlots: { customRender: 'action' },
    },
  ];
export default {
  props: ['schoolList'],
  data(){
    return {
      campusList: [],
      classList: [],
      branchList: [],
      worksheetList :[],
      columns,
      tableLoading: false,
    }
  },
  methods: {
    async handleChangeSchool(schoolId) {
      this.campusList = [];
      this.classList = [];
      this.branchList = [];
      const result = await campusService.getCampusList(schoolId);
      this.campusList = result;
    },
    async handleChangeCampus(campusId) {
      this.classList = [];
      this.branchList = [];
      const result = await classService.getClassList(campusId);
      this.classList = result;
      
    },
    async handleChangeClass(classId) {
      this.branchList = [];
      const result = await branchService.getBranchList(classId);
      this.branchList = result;
    },
    async handleChangeBrach(branchId) {
      this.tableLoading = true;
      const result = await worksheetService.getWorksheets(branchId);
      this.worksheetList = result;
      this.tableLoading = false;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    async deleteWorksheet(worksheetId) {
      const result = await worksheetService.deleteWorksheet(worksheetId);
      if(result) this.$message.success('Worksheet Deleted!'); 
    }
  },
}
</script>
<style scoped>
.select-box {
  width: 100%;
  margin: 10px 0px;
}
</style>