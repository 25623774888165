import api from './base';

export default{
  async getBranchList(payload) {
    const result = await api().get('/api/Branch/GetByClassID/' + payload);
    return result.data;
  },
  async deleteBranch(payload) {
    const result = await api().delete('/api/Branch/' + payload);
    return result.data;
  },
  async createBranch(payload) {
    const result = await api().post('/api/Branch', payload);
    return result.data;
  },
}